$neutral: (
  1: #ffffff,
  2: #ebeff2,
  3: #e2e8ed,
  4: #d8e0e5,
  5: #c5d1d8,
  6: #b6c3cc,
  7: #a3b5bf,
  8: #96a8b2,
  9: #889aa5,
  10: #748b99,
  // Figma and this codebase need to be synced
    // this. 11 === 10-2 on Figma
    11: #677e8c,
  12: #59717f,
  13: #4c6472,
  14: #425866,
  15: #364c59,
  16: #2c404c,
  17: #23343f,
  18: #1b2a33,
  19: #121f26,
  20: #0c1419,
  21: #05090c,
  22: #000000
);

$primary: (
  "main": #007acc,
  "hover": #005c99,
  "active": #003d66,
  "inverse": #eefbff
);

$selected: (
  "main": #09818c,
  "hover": #065769,
  "active": #032c36,
  "inverse": #e6fbff
);

$important: (
  "main": #f58700,
  "hover": #ff8e00,
  "active": #8f4f00,
  "inverse": #fff7ee
);

$dark-primary: (
  "main": #45ace5,
  "hover": #2d7399,
  "active": #1e4d66,
  "inverse": #eef9ff
);

@function get-color($color, $variation: "main") {
  @if map-has-key($color, $variation) {
    @return map-get($color, $variation);
  } @else {
    @warn "No match was found in map `#{$color}` for `#{$variation}`";
  }
}

$families: (
  "primary": $primary,
  "dark-primary": $dark-primary,
  "imporant": $important,
  "selected": $selected,
  "neutral": $neutral
);

@mixin generateColors($families) {
  @each $familyName, $family in $families {
    @each $name, $hex in $family {
      .text__#{$familyName}--#{$name} {
        color: $hex;
      }
      .background__#{$familyName}--#{$name} {
        color: $hex;
      }
    }
  }
}

@include generateColors($families);
