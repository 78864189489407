//Remove the unit of  length (eg rem, px)
@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@import "./typography-variables";

/*
We use rem because we're cool. But because
we're cool we should support other fallback options,
and will do so throughout the app.. that's why it's
good to start at px and convert up.
*/
@function calculateRem($size) {
  $remSize: ((strip-unit($size) / strip-unit($base-font-size)));
  @return #{$remSize}rem;
}

@mixin responsive-font(
  $min-size: $font-size-min,
  $size: $font-size,
  $limit: $page-limit
) {
  // for the scaling font to work, it must
  // use a value passed as a css variable
  // if it exists we're obviously wanting
  // a scaling font
  $scale-font-enable: global-variable-exists(scale);
  // min font size
  $unitless-min: strip-unit($min-size);
  // font size
  $unitless-size: strip-unit($size);
  // min breakpoint is used for the baseline
  $baseline: strip-unit($min-mobile);
  // value used to scale font
  $font-multiplier: (
    ($unitless-size - $unitless-min) / (strip-unit($limit) - $baseline)
  );
  // baselines in typography are tricky, to get the text sitting nicely..
  $font-baseline: ($unitless-min - $font-multiplier * $baseline);

  @if $unitless-min >= $unitless-size {
    @warn 'Responsive font: min-size equal or greater than size';
  }

  // fallback px font
  font-size: #{$unitless-min}px;

  @if $scale-font-enable == true {
    // the scaling font magic as px
    font-size: calc(#{$unitless-min}px * #{var(--scale-font)});
    // the scaling font magic as rem
    $remSize: calculateRem($unitless-min);
    font-size: calc(#{$remSize} * #{var(--scale-font)});
  }

  /*
  Mobile breakpoint
  */
  @media (min-width: $min-mobile) {
    // px fallback
    font-size: #{$unitless-min}px;

    @if $scale-font-enable == true {
      // scaling font as px
      font-size: calc(
        (#{$font-multiplier} * #{100vw} + (#{$font-baseline}px)) *
          #{var(--scale-font)}
      );
      // scaling font as rem
      $remSizeMobile: calculateRem($font-baseline);
      font-size: calc(
        (#{$font-multiplier} * #{100vw} + (#{$remSizeMobile})) *
          #{var(--scale-font)}
      );
    } @else {
      font-size: calc((#{$font-multiplier} * #{100vw} + (#{$font-baseline}px)));
      $remSizeFallback: calculateRem($font-baseline);
      font-size: calc((#{$font-multiplier} * #{100vw} + (#{$remSizeFallback})));
    }
  }

  @media (min-width: $limit) {
    // px fallback
    font-size: #{$unitless-size}px;

    @if $scale-font-enable == true {
      // scaling font as px
      font-size: calc(#{$unitless-size}px * #{var(--scale-font)});
      // scaling font as rem
      $remSizeLarge: calculateRem($unitless-size);
      font-size: calc(#{$remSizeLarge} * #{var(--scale-font)});
    }
  }
}

// Alias for responsive-font mixin
@mixin rf($args...) {
  @include responsive-font($args...);
}

// Quick access to typography breakpoints

@mixin bp($size: desktop) {
  @if $size == desktop {
    @media (min-width: $min-desktop) {
      @content;
    }
  }
  @if $size == tablet {
    @media (min-width: $min-tablet) {
      @content;
    }
  }
  @if $size == mobile {
    @media (min-width: $min-mobile) {
      @content;
    }
  }
}

//  Margin mixins -- These are used to maintain the intergrity of the vertical rhythm

@mixin vertical-margin($number, $direction: bottom) {
  // Base fontsize unitless.
  $base: ($unitless-font-size * 10) + $unitless-font-size;
  // The font leading, calculated by font size and line height
  $leading: $base * $line-height;
  // Unitless px size value
  $pxSize: $number * $leading;

  // margin bottom
  @if ($direction == "bottom") {
    // px fallback
    margin-bottom: #{$pxSize + "px"};
    // rems
    margin-bottom: calculateRem($pxSize);
  }

  // margin top
  @if ($direction == "top") {
    // px fallback
    margin-top: #{$pxSize + "px"};
    // rems
    margin-top: calculateRem($pxSize);
  }

  // margin ends
  @if ($direction == "ends") {
    // px fallback
    margin: #{$pxSize + "px"} 0;
    // rems
    margin: calculateRem($pxSize) 0;
  }
}

@mixin vertical-padding($number, $direction: bottom) {
  // Base fontsize unitless.
  $base: ($unitless-font-size * 10) + $unitless-font-size;
  // The font leading, calculated by font size and line height
  $leading: $base * $line-height;
  // Unitless px size value
  $pxSize: $number * $leading;

  // padding bottom
  @if ($direction == "bottom") {
    // px fallback
    padding-bottom: #{$pxSize + "px"};
    // rems
    padding-bottom: calculateRem($pxSize);
  }

  // padding top
  @if ($direction == "top") {
    // px fallback
    padding-top: #{$pxSize + "px"};
    // rems
    padding-top: calculateRem($pxSize);
  }

  // padding ends
  @if ($direction == "ends") {
    // px fallback
    padding: #{$pxSize + "px"} 0;
    // rems
    padding: calculateRem($pxSize) 0;
  }
}
