@import "../utility/variables";
@import "typography-variables";
@import "typography-mixins";

html {
  font-size: $base-font-size;
}

body {
  font-size: $font-size;
  line-height: $line-height;
  font-family: $font-family;
}

.legal {
  font-family: inherit;
  line-height: inherit;
  @include bp(mobile) {
    // px
    font-size: 11.24px;
    // rem
    font-size: calculateRem(11.24);
  }
  @include bp(tablet) {
    // px
    font-size: 8.19px;
    // rem
    font-size: calculateRem(8.19);
  }
}

.caption,
figcaption,
caption {
  font-family: inherit;
  line-height: inherit;
  @include bp(mobile) {
    // px
    font-size: 12.64px;
    // rem
    font-size: calculateRem(12.64);
  }
  @include bp(tablet) {
    // px
    font-size: 10.24px;
    // rem
    font-size: calculateRem(10.24);
  }
}

small,
.small {
  font-family: inherit;
  line-height: inherit;
  @include bp(mobile) {
    // px fallback
    font-size: 14.22px;
    // rem
    font-size: calculateRem(14.22);
    line-height: $line-height;
  }
  @include bp(tablet) {
    // px fallback
    font-size: 12.8px;
    // rem
    font-size: calculateRem(12.8);
    line-height: $page-limit;
  }
}

p,
.body-text {
  font-family: inherit;
  line-height: inherit;
  @include rf(14, 16);

  max-width: $line-length;
}

p.lead-text,
.lead-text {
  @include rf(17, 17.5);
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5,
.heading--giga,
.heading--mega {
  font-family: $font-heading;
  line-height: $line-height--heading;
  letter-spacing: -0.03rem;
  max-width: $line-length--heading;
}

h6,
.h6 {
  font-family: inherit;
  line-height: $line-height--heading;
  @include rf(18.5, 18);
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.164rem;
}

h5,
.h5 {
  letter-spacing: initial;
  @include bp(mobile) {
    // px
    font-size: 18px;
    // rem
    font-size: calculateRem(18);
  }
  @include bp(tablet) {
    // px
    font-size: 16px;
    // rem
    font-size: calculateRem(16);
  }
}

h4,
.h4 {
  @include rf(20.25, 25);
}

h3,
.h3 {
  @include rf(22.78, 31.25);
}

h2,
.h2 {
  @include rf(25.63, 39.06);
}

h1,
.h1 {
  @include rf(28.83, 48.33);
}

// Display headings (bigger than the average h1)

.heading--giga {
  display: block;
  @include rf(32.49, 76.29);
}
.heading--mega {
  display: block;
  @include rf(32.44, 61.04);
}

// Good typography patterns

// hanging punctuation
blockquote p,
.hanging-punctuation {
  text-indent: -0.5rem;
}

// hanging lists
ul,
ol {
  padding-left: 0;
  list-style-position: outside;
  line-height: $line-height;
}

ul {
  list-style: initial;
}

ol {
  list-style: decimal;
}

// Headings Default margin-bottom and top values.

$headings: (
  // h#: ($margin-bottom, $margin-top)
    ".heading--giga": (1, 3),
  ".heading--mega": (1, 3),
  h1: (1, 3),
  h2: (1, 2.5),
  h3: (1, 2),
  h4: (1, 2),
  h5: (1, 1.5),
  h6: (1, 1.5),
  p: (1, 0)
);

@each $heading, $props in $headings {
  #{$heading} {
    @include vertical-margin(nth($props, 1));
    @include vertical-margin(nth($props, 2), top);
  }
}

ol,
ul {
  @include vertical-margin(1, ends);
}

// Utility functions

.u-type--uppercase {
  text-transform: uppercase;
}

strong,
.u-type--strong {
  font-weight: 600;
}

.u-type--light {
  font-weight: 300;
}

i,
.u-type--italic {
  font-style: italic;
}

.u-flush {
  margin: 0;
}

.u-flush--bottom {
  margin-bottom: 0;
}

.u-flush--top {
  margin-top: 0;
}

.u-flush--ends {
  margin-top: 0;
  margin-bottom: 0;
}

.u-type--tight {
  letter-spacing: -0.03rem;
}

.u-type--loose {
  letter-spacing: 0.03rem;
}

.u-type--center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

// utility vertical margins and paddings
$sizes: (
  "sm": 1,
  "md": 2,
  "lg": 3
);
$directions: ("ends", "top", "bottom");
.u-vertical {
  &-margin {
    @each $key, $size in $sizes {
      @each $direction in $directions {
        &--#{$direction}--#{$key} {
          @include vertical-margin($size, $direction);
        }
      }
    }
  }
  &-padding {
    @each $key, $size in $sizes {
      @each $direction in $directions {
        &--#{$direction}--#{$key} {
          @include vertical-padding($size, $direction);
        }
      }
    }
  }
}
