@import "./utility/reset";
@import "./utility/mixins";
@import "./typography/_typography.scss";
@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

:root {
  @if global-variable-exists(scale) {
    --scale-element: 1;
    --scale-font: 1;

    @each $size in $scale {
      @media (max-height: nth($size, 1)) {
        --scale-element: #{nth($size, 2)};
        --scale-font: #{nth($size, 2)};
      }
    }
  }
}

:root {
  --scale-multiplier: 1;
}

html {
  box-sizing: border-box;
}

*,
* * {
  &,
  &:after,
  &:before {
    box-sizing: inherit;
  }
}

html,
body {
  min-height: 100vh;
}

html {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll; // always show the vertical scrollbar so that content doesn't jump

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;
}
// inherited from <html>
*,
*::before,
*::after {
  box-sizing: inherit;
}

@mixin baselineGrid() {
  $line-color: rgba(20, 20, 230, 0.2);
  background: repeating-linear-gradient(
    to bottom,
    $line-color 1px,
    transparent 2px,
    transparent 2.6rem,
    $line-color 2.7rem
  );
}

// IMPORT CUSTOM APP STYLES FROM HERE:
html {
  font-size: 10px;
}

.container {
  @include container;
}

.main {
  margin: 0 auto;
  max-width: calculateRem($page-limit);
}

// Component styles

// Imported here rather than in the components
// to allow the SASS utilities to be exposed
// to the components
@import "./colour/colour";
@import "./components/styles/Hero.scss";
@import "./components/styles/Button.scss";
