.button {
  // Variables
  $transition: 150ms ease-in-out;
  $delay: 150ms;
  $main: get-color($primary, main);
  $hover: get-color($primary, hover);
  $active: get-color($primary, "active");
  $inverse: get-color($primary, inverse);
  //   Resets
  &,
  &:active,
  &:hover,
  &:visited {
    text-decoration: none;
    outline: none;
    border: none;
    cursor: pointer;
  }

  // Base font work
  font-weight: 600;

  &--large {
    @include rf(20.25, 25);
  }

  color: $main;

  $baseButtonTransitions: color $transition $delay;
  transition: $baseButtonTransitions;

  &:hover {
    color: $hover;
  }
  &:active {
    color: $active;
  }

  &--outline {
    $border: 1.8px solid;
    border: $border $main;
    border-radius: 0.8rem;
    background-color: transparent;
    transition: $baseButtonTransitions, border $transition $delay,
      background-color $transition $delay;

    &.button--large {
      padding: 1.5rem 1.8rem 1.6rem;
    }

    &:hover {
      border: $border $hover;
      background-color: $hover;
      color: $inverse;
    }
    &:active {
      background-color: $active;
      border: $border $active;
      color: $inverse;
    }
  }
}
