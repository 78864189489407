// the fonts for heading and body
$font-family: "NeueHaasGroteskText", fantasy;
$font-heading: "NHaasGrotesk-65Md", fantasy;
// the size set at the html root
$base-font-size: 10px;
// the desired font size (set in body)
$font-size: 16px;
// Used to calculate margin bottom for elements
$unitless-font-size: strip-unit($font-size) / strip-unit($base-font-size);

// the desired mobile size
$font-size-min: 14.22px;
// the line height.
$line-height: 1.64;
// headings/display line height
$line-height--heading: $line-height - 0.43;

$line-length: 73rem;
$line-length--heading: $line-length + 10rem;
