html {
  background: linear-gradient(
    118.52deg,
    get-color($neutral, 19) 2.94%,
    get-color($neutral, 21) 90.01%
  );
}

.hero {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__inner {
    @include container;
    display: grid;
    height: 100%;
    grid-template-columns: repeat(12, minmax(10rem, 1fr));
    grid-template-rows: min-content 1fr;
    grid-column-gap: 1.8rem;
    grid-template-areas:
      "nav nav nav nav nav nav nav nav nav nav nav nav"
      "display display display display display display content content content content ws ws";

    @media screen and (max-width: $min-1280) {
      grid-template-rows: min-content 1fr;
      grid-template-areas:
        "nav nav nav nav nav nav nav nav nav nav nav nav"
        "display display display ws content content content content content content content ws";
    }

    @media screen and (max-width: 1165px) {
      grid-template-rows: min-content max-content 1fr;
      grid-template-areas:
        "nav nav nav nav nav nav nav nav nav nav nav nav"
        "content content content content content content content content content content content content"
        "display display display display display display display display display display display display";
    }
  }

  &__logo {
    max-width: 11.9rem;
    padding: 1.6rem 0;
  }
  &__nav {
    grid-area: nav;
    display: flex;
    justify-content: center;
  }

  &__display {
    grid-area: display;
  }

  &__content {
    grid-area: content;
    display: grid;
    grid-template-rows: min-content min-content min-content;
    @media screen and (max-width: 1165px) {
      margin-bottom: 3.2rem;
    }
  }
}

.hero-display {
  &__image {
    height: 80%;
  }
}

.hero-content {
  &__heading {
  }
  &__subheading {
  }
  &__cta {
  }
}

//   &:after {
//     content: "";
//     width: 100%;
//     height: 8rem;
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-image: url("data:image/svg+xml,%3Csvg width='1525' height='409' viewBox='0 0 1525 409' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M1474 45.5001H34V386C34 386 967.501 35.5001 1474 251.5C1512.47 267.907 1474 251.5 1465 192V142L1474 45.5001Z' fill='url(%23paint0_linear)'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0' y='0.500061' width='1524.78' height='408.5' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='-11'/%3E%3CfeGaussianBlur stdDeviation='17'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.258824 0 0 0 0 0.345098 0 0 0 0 0.4 0 0 0 0.2 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3ClinearGradient id='paint0_linear' x1='34.0001' y1='81.0797' x2='497.7' y2='857.89' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23121F26'/%3E%3Cstop offset='1' stop-color='%2305090C'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
//   }
