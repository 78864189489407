// page dimensions
// Global page dimensions
$page-width: 1440px;

// gutter (padding left + right)
$gutter: 30px;
$container-gutter-mobile: $gutter;
$container-gutter-tablet: 50px;
$container-gutter-desktop: 70px;
// max-width
$page-limit: $page-width + ($container-gutter-desktop * 2);

// Segment / Section padding
$segment-padding-mobile: $gutter * 3;
$segment-padding: 200px; //percentage-based segments spacing
// Vertical scaling values.
// remove this map to disable vertical scaling
// value 1 = height breakpoint, value 2 = multiplier
$scale: ((920px, 0.975), (800px, 0.95), (690px, 0.925));

// grid
$grid-column-count: 12;
$grid-baseline: 16px;

@import "./breakpoints";
// Predefined breakpoints
$min-mobile: $min-375;
$min-tablet: $min-720;
$min-desktop: $min-1080;
