@mixin container() {
  flex-grow: 1;

  margin: 0 auto;
  padding-left: $container-gutter-mobile;
  padding-right: $container-gutter-mobile;

  max-width: $page-width + $container-gutter-mobile;

  @media (min-width: $min-mobile) {
    max-width: $page-width + $container-gutter-mobile * 2;
  }

  @media (min-width: $min-tablet) {
    padding-left: $container-gutter-tablet;
    padding-right: $container-gutter-tablet;
    max-width: $page-width + $container-gutter-tablet * 2;
  }

  @media (min-width: $min-desktop) {
    padding-left: $container-gutter-desktop;
    padding-right: $container-gutter-desktop;
    max-width: $page-width + $container-gutter-desktop * 2;
  }
}

@mixin absolutePseudoElement() {
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    pointer-events: none;
    @content;
  }
}
